<template>

  <div>
    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <left-bar-comp>
      <sports-left-bar-comp></sports-left-bar-comp>
    </left-bar-comp>

    <div class="content_section">
      <div class="content">
        <sub-title>1:1문의</sub-title>
        <div class="main_panel">
          <div class="qa_item" v-for="(item, index) in questionList" :key="index">
            <div class="qa">
              <div class="qt">
                <span>{{item.createTime|datef('MM/DD HH:mm')}} </span>
                <button v-if="item.status == sportsConst.STATUS_REPLYED" @click="del(item.id)"  style="background-color: red;padding: 2px 5px;border-radius: 3px;">삭제하기</button>
              </div>
              <div class="q" v-html="item.content" @click="showReplay(item.id)">
              </div>
              <div class="as">
                <div class="qt">
                  <span v-if="item.contentReply" @click="showReplay(item.id)" style="cursor: pointer">
                    <span>{{item.updateTime|datef('MM/DD HH:mm')}} (답변완료) </span><i class="fa fa-hand-o-down" aria-hidden="true" style="color: #fff9ff;font-size: 16px"></i> [클릭]
                  </span>
                  <span v-else @click="showReplay(item.id)" style="color: #fff9ff">
                    <i class="fa fa-share" aria-hidden="true"></i> <span>답변대기중</span>
                  </span>
                </div>
                <div class="q" v-if="item.contentReply" v-html="item.contentReply" v-show="currentId == item.id">

                </div>

              </div>

            </div>
          </div>
        </div>

        <!--페이지-->
        <pagination :page-index="pageNum"
                    :total="total"
                    :page-size="pageSize"
                    @change="pageChange"
                    v-if="questionList.length > 0"></pagination>

        <div class="main_panel">
          <div class="qa_item">
            <div class="qa">
              <div class="qt">
                <i class="fa fa-pencil" aria-hidden="true"></i> 글쓰기
              </div>
              <textarea v-model="content" rows="10"></textarea>
              <div class="btn-area">
                <button class="btn-write" @click="saveQuestion()">등록
                </button>
                <button class="btn-write" style="background-color: #ffa500" @click="questioAcNo">계좌문의
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- right 메뉴 -->
    <right-bar-comp>
      <!--내정보-->
      <user-info-comp slot="userinfo"></user-info-comp>
      <right-buttons-comp slot="btns"></right-buttons-comp>
      <!--배팅카트-->
      <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
      <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
    </right-bar-comp>

    <foot-comp></foot-comp>

  </div>


</template>

<script>
  import RightBarComp from "../../components/RightBarComp";
  import SubTitle from "../../components/SubTitle";
  import {delQuestion, getQuestionList, saveQuestion} from "../../network/userRequest";
  import Pagination from "../../components/pagenation/Pagination";
  import LeftBarComp from "../../components/LeftBarComp";
  import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
  import FootComp from "../../components/FootComp";
  import TopbarComp from "../../components/TopbarComp";
  import RightBarBannerComp from "../../components/RightBarBannerComp";
  import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
  import sportsConst from "../../common/sportsConst";
  import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
  import {postionMixin} from "../../common/mixin";
  import UserInfoComp from "../../components/UserInfoComp";
  import RightButtonsComp from "../../components/RightButtonsComp";
  import SportsBetCartComp from "../../components/sports/SportsBetCartComp";

  export default {
    name: "CustomerCenter",
    mixins:[postionMixin],
    components: {
      SportsBetCartComp,
      RightButtonsComp,
      UserInfoComp,
      SportsLeftBarComp,
      RightBarBannerComp,
      TopbarComp, FootComp, LeisureLeftComp, LeftBarComp, Pagination, SubTitle, RightBarComp
    },
    data() {
      return {
        pageNum: 1,
        pageSize: 3,
        total: 1,
        orderBy: null,
        search: {},
        questionList: [],
        content: null,
        sportsConst,
        currentId: 0,
        position:"고객센터",
        type: sportsConst.CUSTOMER_TYPE_NORMAL,
        editorOption: {
          modules: {
            toolbar: [
              ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
              [{font: []}], // 글자체
              /*["blockquote", "code-block"], // 引用  代码块
              [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
              [{ script: "sub" }, { script: "super" }], // 上标/下标
              [{ indent: "-1" }, { indent: "+1" }], // 缩进
              // [{'direction': 'rtl'}],                         // 文本方向
              [{ size: ["small", false, "large", "huge"] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              [{ align: [] }], //
              ["clean"], // 문구격식삭제
              ["link", "image", "video"] // 링크、이미지、동영상*/
            ], //기능바 세팅
          },
          placeholder: '', //提示
          readyOnly: false, //是否只读
          theme: 'bubble', //skin snow/bubble
          syntax: false, //문법체크
        },
      }
    },

    methods: {
      del(id){
        delQuestion(id).then(res=>{
          if (res.data.success) {
            this.initQuestion()
          }else {
            this.$swal({
              title: res.data.msg,
              type: 'error',
              showCancelButton: false,
              showConfirmButton: true
            })
          }
        })
      },
      showReplay(id){
        if(this.currentId === id){
          this.currentId = 0
        } else {
          this.currentId = id
          this.initQuestion()
        }
      },
      saveQuestion() {
        // let regx = /^(?=.*[가-힣ㄱ-ㅎ].*).{1,}$/
        // if (!(regx.test(this.content))) {
        //   //if(false){
        //   this.$swal({
        //     title: '한글이 포함된 내용으로 작성해주세요',
        //     type: 'error',
        //     showCancelButton: false,
        //     showConfirmButton: true
        //   })
        //   return false;
        // }
        this.$store.commit(RECEIVE_SHOW_LOADING)
        saveQuestion({'content': this.content, 'type': this.type}).then(res => {
          this.$store.commit(RECEIVE_HIDE_LOADING)
          this.type = sportsConst.CUSTOMER_TYPE_NORMAL
          if (res.data.success) {
            this.content = ''
            this.$swal({
              title: '문의글 작성이 완료되였습니다',
              type: 'success',
              showCancelButton: false,
              showConfirmButton: true
            })

            this.initQuestion()
          } else {
            this.$swal({
              title: res.data.msg,
              type: 'error',
              showCancelButton: false,
              showConfirmButton: true
            })
          }
        })
      },
      questioAcNo() {
        this.content = '<p>계좌번호 문의합니다</p>'
        this.type = sportsConst.CUSTOMER_TYPE_BANK_CARD
        this.saveQuestion()
      },
      initQuestion() {
        this.$store.commit(RECEIVE_SHOW_LOADING)
        getQuestionList(this.pageNum, this.pageSize, this.orderBy).then(res => {
          this.$store.commit(RECEIVE_HIDE_LOADING)
          if (res.data.success) {
            this.total = res.data.total
            this.questionList = res.data.data
          }
        })
      },
      //pagination.vue 에서 페이지 클릭 시 emit발송접수 메소드
      pageChange(page) {
        this.pageNum = page
        this.initQuestion()
      },

      onEditorBlur(editor) {
      },
      onEditorFocus(editor) {
      },
      onEditorReady(editor) {
      },
      onEditorChange(editor) {
        this.content = editor.html;
      },
    },
    computed: {
      editor() {
        return this.$refs.contentEditor.quillEditor
      }
    },
    mounted() {
    },
    created() {
      this.initQuestion()
    }
  }
</script>

<style scoped>
  @import url("../../assets/css/base.css");
  @import url("../../assets/css/common.css");
  @import url("../../assets/css/style.css");
  @import url("../../assets/css/media.css");
  @import url("../../assets/css/sports.css");

  .qa_item{
    width: 100%;
    background-color: var(--noticeBg);
    box-sizing: border-box;
    padding: 16px;
    border-bottom: 1px solid #616161;
  }
  .qa_item .qa{
    min-height: 100px;
  }
  .qa_item .qt{
    padding: 16px 0;
    color: #7ad5fd;
    font-weight: 600;
  }
  .qa_item .q{
    background-color: var(--noticeBg2);
    padding: 16px;
    color: #fff9ff;
    cursor: pointer;
  }


  .qa_item .quill-editor {
    border: 1px solid #f19f09 !important;
    border-radius: 4px !important;
  }

  .qa_item .btn-area {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    padding-top: 10px;
  }

  .qa_item .btn-area button {
    width: 180px;
    display: inline-block;
    height: 40px;
    margin: 10px 10px;
    background-color: #00b2ff;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
  }

  .qa_item .quill-editor {
    border: 1px solid #e0e0e0 !important;
    border-radius: 1px !important;
    background-color: #ffffff !important;
    color: #0c0e0e;
  }


</style>